<template>
  <block
    component-name="block-text-chapter-media-couple"
    body-class="flex flex-col items-center gap-y-3xl"
  >
    <template v-slot:default="blockData">
      <!-- MEDIAS -->
      <div
        class="relative top-0 left-0 flex flex-col tablet:flex-row gap-x-ui gap-y-edge w-full tablet:w-10/12"
      >
        <wrapper-reveal class="w-full tablet:w-1/2" reveal="fade-from-bottom">
          <media
            v-bind="$attrs.data.body.media_a"
            tag="div"
            :loading="blockData?.loading || 'auto'"
            :sizes="mediaSizes"
            class="w-full"
          />
        </wrapper-reveal>

        <wrapper-reveal
          class="w-full tablet:w-1/2"
          reveal="fade-from-bottom"
          :delay="isTablet ? 100 : 0"
        >
          <media
            v-bind="$attrs.data.body.media_b"
            tag="div"
            :loading="blockData?.loading || 'auto'"
            :sizes="mediaSizes"
            class="w-full"
          />
        </wrapper-reveal>
      </div>
      <!-- end MEDIAS -->

      <!-- TEXTS -->
      <div class="relative top-0 left-0 grid grid-cols-12 w-full">
        <div
          class="flex flex-col items-end w-full col-span-full phablet:col-start-2 phablet:col-end-12 laptop:col-start-3 laptop:col-end-11 extdesktop:col-start-4 extdesktop:col-end-10 gap-2xl"
        >
          <header class="flex flex-col w-full gap-xs">
            <wrapper-reveal
              v-if="$attrs.data.body.overline"
              reveal="fade-from-right"
              class="w-full"
            >
              <div
                v-html="$attrs.data.body.overline"
                class="w-full text-overline"
              ></div>
            </wrapper-reveal>

            <wrapper-split-text reveal="chars" class="w-full">
              <h3
                v-html="$attrs.data.body.title"
                class="w-full text-heading-5"
              ></h3>
            </wrapper-split-text>
          </header>

          <div
            v-if="
              $attrs.data.body.text ||
              $attrs.data.body.button?.title ||
              $attrs.data.body.button?.action
            "
            class="flex flex-col w-full gap-2xl"
            :class="{
              'tablet:w-11/12': $attrs.data.body.text,
            }"
          >
            <wrapper-reveal
              v-if="$attrs.data.body.text"
              reveal="fade-from-bottom"
              class="w-full"
            >
              <div
                v-html="$attrs.data.body.text"
                class="w-full text-body-big"
              ></div>
            </wrapper-reveal>

            <wrapper-reveal
              v-if="$attrs.data.body.button?.title || $attrs.data.body.button?.action"
              class="w-full"
              reveal="fade-from-right"
            >
              <btn-pill
                :title="$attrs.data.body.button.title"
                :action="$attrs.data.body.button.action"
                icon="arrow-long-right"
              />
            </wrapper-reveal>
          </div>
        </div>
      </div>
      <!-- end TEXTS -->
    </template>
  </block>
</template>

<script setup>
const mediaSizes = ref({
  base: 600,
  phablet: 1000,
  tablet: 500,
  laptop: 600,
  desktop: 750,
  extdesktop: 1000,
});

const isTablet = useMediaQuery("(min-width: 1020px)");
</script>
